<template>
    <calc-result-page>
      <div class="center">
        <div class="title-text">预计退休后养老金有:</div>

        <div class="top">{{res.annuity}}<span>元/月</span></div>
        <div class="tip">缴纳{{res.feesYear}}年，平均缴费基数{{res.paymentBase}}</div>
        <div class="tip">退休时间{{res.retireDate}}年，预计当年社平工资{{res.averageSalary}}元/月</div>
      </div>

        <fee-consititute-container>
          <res-list-item title="基础养老金" :content="res.basicAnnuity + '元'"></res-list-item>
          <res-list-item title="过渡性养老金" :content="res.transitionAnnuity + '元'"></res-list-item>
          <res-list-item title="个人账户养老金" :content="res.individualAnnuity + '元'"></res-list-item>
          <res-list-item title="调节金" :content="res.adjustAnnuity + '元'"></res-list-item>
          <res-list-item title="其他待遇" :content="res.otherAnnuity + '元'"></res-list-item>
        </fee-consititute-container>

        <div v-if="isShow" class="hint" @click="onAdvanced">精准录入，算的更准</div>

    </calc-result-page>
</template>

<script>
import CalcResultPage from '@/components/CalcResultPage'
import ResListItem from "@/views/calc/components/ResListItem";
import FeeConsitituteContainer from '@/views/calc/components/FeeConsitituteContainer.vue';

export default {
  components: {
    CalcResultPage,
    ResListItem, 
    FeeConsitituteContainer,
  },
  data() {
    return{
      isShow: true,
      res: {}
    }
  },
  mounted() {
    const { isShow, res } = this.$route.query
    this.isShow = isShow
    this.res = JSON.parse(res)
  },
  methods: {
    onAdvanced() {
      this.$router.push({name:'BasicAnnuityCalcAdvancedPage'})
    },
  }
}
</script>

<style scoped>
  .center {
    padding: 0 14px;
  }

  .title-text {
    font-size: 24px;
    line-height: 33px;
    color: #4D4D4D;
  }

  .top {
    font-size: 44px;
    font-weight: bold;
    line-height: 48px;
    color: #658CF1;
  }

  .top span {
    font-size: 16px;
    font-weight: bold;
    color: #999999;
  }

  .tip {
    font-size: 16px;
    line-height: 22px;
    color: #658CF1;
    margin-top: 4px;
  }

  .hint {
    width: 200px;
    margin-top: 80px;
    font-size: 16px;
    color: #658CF1;
    padding: 2px 0;
    border-radius: 5px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 46px;
  }

</style>